import React from "react";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from "react-bootstrap";
import BackgroundImage from "gatsby-background-image";
import Layout from "../components/layout";

import SEO from "../components/seo";
import Maintenance from "../components/maintenance";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const PortfolioPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "portfolio-background.jpg" }) {
        childImageSharp {
          # Specify a fluid image and fragment
          # The default maxWidth is 800 pixels
          fluid(maxHeight: 550, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
    }
  `);

  const HeroText = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  `;

  const BackgroundImg = styled(Img)`
    max-height: 550px;
    opacity: 0.3;
    &::after {
      background: "rgba(0, 0, 0, 0.6)";
      opacity: "1";
    }
  `;

  const Layer1 = styled.div`
    background: rgba(0, 0, 0, 0.6);
    opacity: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
  `;

  return (
    <Layout location={location}>
      <SEO title="Portfolio" />
      <div style={{ maxHeight: "550px", height: "100%" }}>
        <BackgroundImage
          Tag="section"
          fluid={data.file.childImageSharp.fluid}
          backgroundColor={`#040e18`}
          style={{ height: "100%" }}
        >
          <h1>Hello gatsby-background-image</h1>
        </BackgroundImage>
        {/*<BackgroundImg*/}
        {/*  fluid={data.file.childImageSharp.fluid}*/}
        {/*  objectFit="cover"*/}
        {/*  alt="portfolio-background"*/}
        {/*/>*/}
        {/*<Layer1 />*/}
        {/*<HeroText>*/}
        {/*  <p>See my past portfolio</p>*/}
        {/*  <p>Where I made fast web apps</p>*/}
        {/*  <p>Where I do awesome stuff with technology</p>*/}
        {/*  <p>Where I teach software development skills</p>*/}
        {/*</HeroText>*/}
      </div>
      {/*<Container style={{ height: "100%", backgroundColor: "#F4F7FB" }}>*/}
      {/*  <div style={{ maxHeight: "200px" }}>*/}
      {/*    <Img*/}
      {/*      fluid={data.file.childImageSharp.fluid}*/}
      {/*      objectFit="cover"*/}
      {/*      alt="portfolio-background"*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</Container>*/}
    </Layout>
  );
};

export default PortfolioPage;
